import styled from "@emotion/styled"
import * as Dialog from "@radix-ui/react-dialog"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Value } from "@stringtale/react"
import { LandscapeYoutubePlayer } from "app/content-blocks/components/YoutubePlayer"
import MinimizedButton from "app/core/components/MinimizedButton"
import usePopoverSeen from "app/core/hooks/usePopoverSeen"
import { PageType } from "db"
import { AnimatePresence, motion } from "framer-motion"
import Link from "next/link"
import { PX24 } from "theme/sizes"
import Group from "ui/Group"
import { Close, Content, Overlay, Root } from "ui/Modal"
import RoundedButton from "ui/RoundedButton"
import Stack from "ui/Stack"
import Title from "ui/Title"

const HOME_MODAL_KEY: PageType = "HOME_NIEUWSFLITS_21"
const today = new Date()
const currentYear = today.getFullYear()

export default function HomeModal() {
  const { state, toggle } = usePopoverSeen(HOME_MODAL_KEY)

  const modalActive = state === "OPENED"
  if (state === "CLOSED") {
    return null
  }

  return (
    <Dialog.Root
      open={modalActive}
      onOpenChange={(val) => toggle(val ? "OPENED" : "MINIMIZED")}
    >
      {
        <AnimatePresence>
          {!modalActive && (
            <Dialog.Trigger asChild>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <MinimizedButton onClose={() => toggle("CLOSED")}>
                  <Value name="apps.web.src.home.components.homemodal.button">
                    Nieuwsflits #21
                  </Value>
                </MinimizedButton>
              </motion.div>
            </Dialog.Trigger>
          )}
        </AnimatePresence>
      }
      <Dialog.Portal>
        <Dialog.Overlay asChild>
          <Overlay />
        </Dialog.Overlay>
        <Dialog.Content>
          <Root>
            <Content>
              <Stack gap="32px">
                <Title size={PX24}>
                  <Value name="apps.web.src.home.components.homemodal.title">
                    Nieuwsflits #21
                  </Value>
                </Title>
                <Stack gap="24px">
                  <LandscapeYoutubePlayer videoId={"ywFlYUacd_k"} />
                  <Stack>
                    <Value
                      name="apps.web.src.home.components.homemodal.body"
                      format={{
                        p: (c) => <p>{c}</p>,
                        li: (c) => <li>{c}</li>,
                        ul: (c) => <ul>{c}</ul>,
                        b: (c) => <b>{c}</b>,
                        muziekWeekLink: (c) => (
                          <Link
                            href={
                              "https://mijn.123zing.nl/categorieen/landelijke-themas/kindermuziekweek-2025"
                            }
                            target="_blank"
                            referrerPolicy="no-referrer"
                          >
                            {c}
                          </Link>
                        ),
                        kartrekkerLink: (c) => (
                          <Link
                            href={
                              "https://123zing.my.site.com/knowledgebase/s/article/schoolbeheer"
                            }
                            target="_blank"
                            referrerPolicy="no-referrer"
                          >
                            {c}
                          </Link>
                        ),
                        dromenLiedLink: (c) => (
                          <Link href={"https://mijn.123zing.nl/les/dromenlied"}>
                            {c}
                          </Link>
                        ),
                        boomwhackerLink: (c) => (
                          <Link
                            href={"https://mijn.123zing.nl/les/boomwhackerlied"}
                          >
                            {c}
                          </Link>
                        ),
                        webinarLink: (c) => (
                          <Link
                            href={
                              "https://123zing.my.site.com/knowledgebase/s/webinars"
                            }
                            target="_blank"
                            referrerPolicy="no-referrer"
                          >
                            {c}
                          </Link>
                        ),
                      }}
                    >
                      {`<p>In deze aflevering:
                        <ul>
                          <li><muziekWeekLink>Kindermuziekweek 2025: Dromen</muziekWeekLink></li>
                          <li>Wist je dat? <kartrekkerLink>Kartrekkers!</kartrekkerLink></li>
                          <li><dromenLiedLink>Nieuw: Dromenlied</dromenLiedLink></li>
                          <li><boomwhackerLink>Nieuw: Boomwhackerlied</boomwhackerLink></li>
                          <li><webinarLink>Webinar Week terugkijken</webinarLink></li>
                        </ul>
                        </p>
                       `}
                    </Value>
                  </Stack>
                </Stack>
                <Group gap="8px" justify="right">
                  <Dialog.Close asChild>
                    <RoundedButton variant="filled">
                      <Value name="apps.web.src.news.components.home.sluiten">
                        Later bekijken
                      </Value>
                    </RoundedButton>
                  </Dialog.Close>
                  {/* <RoundedButton variant="filled">
                    <Value name="apps.web.src.news.components.home.meer_informatie">
                      Meer informatie
                    </Value>
                  </RoundedButton> */}
                </Group>
              </Stack>
              <Dialog.Close asChild>
                <Close>
                  <FaTimes />
                </Close>
              </Dialog.Close>
            </Content>
          </Root>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

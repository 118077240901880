import { GetCurrentUserInput } from "../queries/getCurrentUser"

export const isManager = ({
  currentUser,
  organizationId,
}: {
  currentUser?: GetCurrentUserInput | undefined
  organizationId?: number | null
}) => {
  if (!currentUser || !organizationId || !currentUser.organizationUsers)
    return false

  const currentOrganizationUser = currentUser.organizationUsers.filter(
    (oU) => oU.organizationId === organizationId
  )
  if (currentOrganizationUser.length === 1) {
    if (currentOrganizationUser[0].role === "MANAGER") {
      return true
    }
  }
  return false
}

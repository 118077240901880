import { Routes } from "@blitzjs/next"
import { useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import getThemeCategories, {
  GetCategoriesResult,
} from "app/themes/queries/getThemeCategories"
import useFilePath from "app/files/hooks/useFilePath"
import Image from "next/image"
import Link from "next/link"
import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft"
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight"
import { DARK_BLUE_COLOR, ORANGE_COLOR } from "theme/colors"
import { PX14 } from "theme/sizes"
import Button from "ui/Button"
import useSlider from "utils/slider/useSlider"
import { BOX_SHADOW } from "theme/styles"

const Root = styled.div`
  width: 100%;
`

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  scroll-behavior: smooth;

  @supports (scroll-snap-type: x mandatory) {
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
      display: none;
    }
  }
`

const Items = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;

  & > * {
    scroll-snap-align: start;
  }
`

const ItemRoot = styled(Link)`
  text-decoration: none;
  color: ${DARK_BLUE_COLOR};
  width: 153px;
  display: grid;
  gap: 14px;
`

const ItemImage = styled.div`
  border-radius: 100px;
  width: 100%;
  overflow: hidden;
  width: 153px;
  height: 153px;
  ${BOX_SHADOW};
`

const ItemTitle = styled.div`
  font-size: ${PX14};
  text-align: center;
`

const NavButton = styled(Button)`
  background-color: ${ORANGE_COLOR};
  width: 48px;
  height: 48px;
  border-radius: 50%;
  color: white;
  position: absolute;
  top: calc(50% - 24px);
  box-shadow: 0px 2px 10px #000000bf;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Item = ({
  category,
}: {
  category: NonNullable<GetCategoriesResult>[number]
}) => {
  const image = useFilePath(category.listImage, {
    height: 153 * 2,
    width: 153 * 2,
    fit: "cover",
  })
  let linkArgs =
    category.isLastAdded && category.themes && category.themes.length > 0
      ? Routes.ThemeIndexPage({
          category: category.slug,
          theme: category.themes[0].slug,
        })
      : Routes.CategoryPage({
          category: category.slug,
        })
  // if (category.url) {
  //   linkArgs = {
  //     href: category.url,
  //     pathname: category.url,
  //   }
  // }

  return (
    <ItemRoot {...linkArgs}>
      <ItemImage>
        {image && (
          <Image
            src={image}
            width={153}
            height={153}
            alt={category.displayTitle}
          />
        )}
      </ItemImage>
      <ItemTitle>{category.displayTitle}</ItemTitle>
    </ItemRoot>
  )
}

export default function CategorySlider() {
  const [categories] = useQuery(getThemeCategories, undefined, {
    staleTime: 600000,
  })
  const { next, previous, ref, canGoPrevious, canGoNext } = useSlider({
    gap: 40,
    // amount: 1,
    snapToInterval: 153,
  })
  return (
    <Root>
      <NavButton
        onClick={previous}
        style={{
          left: 0,
          paddingLeft: 3,
          display: canGoPrevious ? "flex" : "none",
        }}
      >
        <FaChevronLeft size={20} />
      </NavButton>
      <Grid ref={ref}>
        <Items>
          {categories.map((category) => (
            <Item key={category.id} category={category} />
          ))}
        </Items>
      </Grid>
      <NavButton
        onClick={next}
        style={{
          right: 0,
          paddingRight: 3,
          display: canGoNext ? "flex" : "none",
        }}
      >
        <FaChevronRight size={20} />
      </NavButton>
    </Root>
  )
}

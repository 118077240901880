import { useElementSize } from "@mantine/hooks"
import { useAnimation } from "framer-motion"
import { useCallback, useEffect, useState } from "react"

import getScrollMax from "./getScrollMax"
import getSnapInterval from "./getSnapInterval"
import getSnapPosition from "./getSnapPosition"
import useChildElmAmount from "./useChildElmAmount"
import useOnScrollToSnapElement from "./useOnScrollToSnapElement"

type Props = {
  gap?: number
  amount?: number
  selector?: string
  snapToInterval?: number
}

const useSlider = (props: Props = {}) => {
  const { gap = 0, amount = 1, selector = ":scope > div > *" } = props
  const { ref, width } = useElementSize<HTMLDivElement>()
  const count = useChildElmAmount(ref, selector)
  const snapToInterval = props.snapToInterval || getSnapInterval(width, gap, amount)

  const max = getScrollMax({
    count,
    snapToInterval,
    gap,
    width,
  })

  const [current, setCurrent] = useState(0)

  const to = useCallback((direction: number) => {
    const c = Math.max(0, Math.min(current + direction, max))
    ref.current.scrollTo({
      left: ((snapToInterval + gap) * c),
      behavior: "smooth",
    })
    setCurrent(c)
  }, [current, gap, max, ref, snapToInterval])


  useOnScrollToSnapElement(ref.current, selector, useCallback((x, y) => {
    setCurrent(Math.round(x / (snapToInterval + gap)))
  }, [snapToInterval, gap]))


  // Get initial position from scroll
  useEffect(() => {
    if (!ref.current) return
    const { scrollLeft } = ref.current
    const point = getSnapPosition(scrollLeft, snapToInterval + gap)
    if (point !== -1) {
      setCurrent(point)
    }
  }, [gap, ref, snapToInterval])

  return {
    canGoNext: max > 0 && current < max,
    canGoPrevious: current > 0,
    current,
    to,
    next: useCallback(() => to(1), [to]),
    previous: useCallback(() => to(-1), [to]),
    width,
    max,
    ref
  }
}

export default useSlider
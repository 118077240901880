import { Routes } from "@blitzjs/next"
import * as Dialog from "@radix-ui/react-dialog"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Value } from "@stringtale/react"
import { SUPPORT_LINKS } from "app/core/consts"
import usePopoverSeen from "app/core/hooks/usePopoverSeen"
import Image from "next/image"
import { useRouter } from "next/router"
import { Dispatch, SetStateAction } from "react"
import { PX14, PX24 } from "theme/sizes"
import Group from "ui/Group"
import { Close, Content, Overlay, Root } from "ui/Modal"
import RoundedButton from "ui/RoundedButton"
import Stack from "ui/Stack"
import SupportTip from "ui/SupportTip"
import Text from "ui/Text"
import Title from "ui/Title"
import { GetBlueprintResult } from "../queries/getBlueprint"

export default function BlueprintModal({
  blueprint,
  setHasJustClosedBlueprintModal,
}: {
  blueprint: Exclude<GetBlueprintResult, null>
  setHasJustClosedBlueprintModal: Dispatch<SetStateAction<boolean>>
}) {
  const { state, toggle } = usePopoverSeen("SCHOOLYEAR")
  const router = useRouter()

  const modalActive = state === "OPENED"
  if (state === "CLOSED" || state === "MINIMIZED") {
    return null
  }

  return (
    <Dialog.Root
      open={modalActive}
      onOpenChange={(val) => {
        toggle(val ? "OPENED" : "CLOSED")
        setHasJustClosedBlueprintModal(true)
      }}
    >
      <Dialog.Portal>
        <Dialog.Overlay asChild>
          <Overlay />
        </Dialog.Overlay>
        <Dialog.Content>
          <Root>
            <Content>
              <Stack gap="24px" align="center">
                <Image
                  src={"/images/schooljaren/toeter.png"}
                  height={120}
                  width={120}
                  style={{ borderRadius: "50%" }}
                  alt="Aankondigingsicoon"
                />
                <Title size={PX24}>
                  <Value
                    format={{ schoolyear: blueprint.displayTitle }}
                    name="apps.web.src.schooljaren.components.blueprintmodal.title"
                  >
                    {`Start met schooljaarovergang {schoolyear}`}
                  </Value>
                </Title>
                {/* <Stack gap="24px">
                  <Value
                    name="apps.web.src.schooljaren.components.blueprintmodal.body"
                    format={{
                      p: (c) => <p>{c}</p>,
                      li: (c) => <li>{c}</li>,
                      ul: (c) => <ul>{c}</ul>,
                    }}
                  >
                    {`Hier moet nog tekst komen die niet is aangeleverd.`}
                  </Value>
                </Stack> */}
                <Group gap="8px" justify="right">
                  <Dialog.Close asChild>
                    <RoundedButton variant="bordered" color="orange">
                      <Value name="apps.web.src.schooljaren.components.schooljaren.sluiten">
                        Ik doe dit later
                      </Value>
                    </RoundedButton>
                  </Dialog.Close>
                  <RoundedButton
                    variant="filled"
                    color="orange"
                    onClick={async () => {
                      await toggle("CLOSED")
                      await router.push(Routes.SchooljarenStep1Page())
                    }}
                  >
                    <Value name="apps.web.src.schooljaren.components.schooljaren.meer_informatie">
                      Start met schooljaarovergang
                    </Value>
                  </RoundedButton>
                </Group>
                <SupportTip
                  href={SUPPORT_LINKS["SCHOOLYEAR"]}
                  withBackground={false}
                >
                  <Text size={PX14}>
                    <Value name="apps.web.src.schooljaren.components.blueprintmodal.hulp_bij_de_schooljaarovergang">
                      Hulp bij de schooljaarovergang
                    </Value>
                  </Text>
                </SupportTip>
              </Stack>
              <Dialog.Close asChild>
                <Close>
                  <FaTimes />
                </Close>
              </Dialog.Close>
            </Content>
          </Root>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

import styled from "@emotion/styled"
import { AnimatePresence } from "framer-motion"
import { DARKER_GREY_COLOR, DARK_BLUE_COLOR } from "theme/colors"
import { HEADER_Z_INDEX } from "theme/consts"
import { PX14 } from "theme/sizes"
import { BOX_SHADOW } from "theme/styles"
import Button from "ui/Button"
import RoundedButton from "ui/RoundedButton"
import { TbSparkles } from "@react-icons/all-files/tb/TbSparkles"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"

const TriggerButton = styled(RoundedButton)`
  position: fixed;
  bottom: 20px;
  right: 80px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  border: 2px solid ${DARK_BLUE_COLOR};
  color: white;
  background-color: ${DARK_BLUE_COLOR};
  cursor: pointer;
  font-weight: bold;
  font-size: ${PX14};
  z-index: ${HEADER_Z_INDEX};
  ${BOX_SHADOW};
`

const CloseTriggerButton = styled(Button)`
  position: absolute;
  background-color: ${DARKER_GREY_COLOR};
  border-radius: 50px;
  color: white;
  top: -8px;
  right: -8px;
  height: 24px;
  width: 24px;
`

export default function MinimizedButton({
  onClose,
  children
}: {
  onClose: () => void,
  children: React.ReactNode
}) {
  return (
    <TriggerButton
      right={<TbSparkles fill={"white"} size={20}></TbSparkles>}
    >
      <CloseTriggerButton
        onClick={async (e) => {
          e.preventDefault()
          onClose()
        }}
      >
        <FaTimes size={14} />
      </CloseTriggerButton>
      {children}
    </TriggerButton>
  )
}

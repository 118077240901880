import styled from "@emotion/styled"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import React from "react"
import {
  BACKGROUND_BLUE,
  BACKGROUND_YELLOW,
  DARK_BLUE_COLOR,
  LIGHT_ORANGE_COLOR,
  ORANGE_COLOR,
  SYSTEM_BLUE,
  SYSTEM_YELLOW,
} from "theme/colors"
import Button from "ui/Button"
import Group from "ui/Group"
import RoundedButton from "ui/RoundedButton"

export type ColorVariant = "blue" | "yellow" | "orange"

const colors: {
  [K in ColorVariant]: { border: string; background: string }
} = {
  yellow: { border: SYSTEM_YELLOW, background: BACKGROUND_YELLOW },
  blue: { border: SYSTEM_BLUE, background: BACKGROUND_BLUE },
  orange: { border: ORANGE_COLOR, background: LIGHT_ORANGE_COLOR },
}

const Root = styled(Group)<{ variant: ColorVariant }>`
  border-radius: 12px;
  box-shadow: 0px 2px 10px #00000033;
  padding: 16px 24px 16px 20px;
  box-sizing: border-box;
  border-width: 1px;
  border-left-width: 12px;
  border-style: solid;
  border-color: ${(p) => colors[p.variant].border};
  background-color: ${(p) => colors[p.variant].background};
  width: 100%;
`

export function HomepageMessageBanner({
  variant = "orange",
  style,
  children,
  buttonText,
  buttonAction,
  onCloseAction,
}: {
  style?: React.CSSProperties
  variant: ColorVariant
  children: React.ReactNode
  buttonText: React.ReactNode
  buttonAction: () => void
  onCloseAction?: () => void
}) {
  return (
    <Root
      align="center"
      justify="space-between"
      style={style}
      gap={"32px"}
      variant={variant}
    >
      {children}

      <Group justify="space-between" gap={"20px"} align="center">
        <RoundedButton
          color={"orange"}
          variant="filled"
          style={{ textDecoration: "none" }}
          onClick={buttonAction}
        >
          {buttonText}
        </RoundedButton>

        {onCloseAction && (
          <Button onClick={onCloseAction} style={{ height: 34, width: 34 }}>
            <FaTimes size={20} color={DARK_BLUE_COLOR} />
          </Button>
        )}
      </Group>
    </Root>
  )
}

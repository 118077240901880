

const getSnapPosition = (scrollLeft: number, snapToInterval: number) => {
  const isAtSnappingPoint = scrollLeft % snapToInterval < 1;
  if (isAtSnappingPoint) {
    return Math.round(scrollLeft / snapToInterval);
  }
  return -1;
}

export default getSnapPosition
import { MutableRefObject, useEffect, useState } from "react"


const useChildElmAmount = (elm: MutableRefObject<HTMLDivElement>, selector: string) => {
  const [count, setCount] = useState(0)
  useEffect(() => {
    if (!elm.current) return
    setCount(elm.current.querySelectorAll(selector).length)
  }, [elm])
  return count
}

export default useChildElmAmount
import { useEffect } from "react"

const check = (a: number, b: number) => a - b > -2 && a - b < 2

const useOnScrollToSnapElement = (elm: HTMLDivElement, selector: string, handler: (x: number, y: number) => void) => {
  useEffect(() => {
    if (!elm) return
    const elements = elm.querySelectorAll(selector)
    const onScroll = () => {
      if (!elements) return
      const { scrollLeft, scrollTop } = elm
      elements.forEach(elm => {
        // @ts-ignore
        const { offsetLeft, offsetTop } = elm
        if (check(offsetLeft, scrollLeft)) {
          handler(offsetLeft, offsetTop)
        }
      })
    }
    onScroll()
    elm.addEventListener("scroll", onScroll)
    return () => {
      elm.removeEventListener("scroll", onScroll)
    }
  }, [elm, handler, selector])
}

export default useOnScrollToSnapElement
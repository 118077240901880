import { Routes } from "@blitzjs/next"
import { invalidateQuery, useMutation } from "@blitzjs/rpc"
import * as Dialog from "@radix-ui/react-dialog"
import { FaTimes } from "@react-icons/all-files/fa/FaTimes"
import { Value, htmlFormatTags } from "@stringtale/react"
import { SUPPORT_LINKS } from "app/core/consts"
import { useCurrentGroup } from "app/core/hooks/useCurrentGroup"
import { useRouter } from "next/router"
import { PX24 } from "theme/sizes"
import Group from "ui/Group"
import Hr from "ui/Hr"
import { Close, Content, Overlay, Root } from "ui/Modal"
import RoundedButton from "ui/RoundedButton"
import Stack from "ui/Stack"
import SupportTip from "ui/SupportTip"
import Title from "ui/Title"
import closeGroupMessage from "../mutations/closeGroupMessage"
import getMigrationDialog from "../queries/getMigrationDialog"

export default function MigrationHomeDialog({
  variant,
}: {
  variant: "MODAL_1" | "MODAL_2" | "MODAL_3"
}) {
  const currentGroup = useCurrentGroup()
  const [closeGroupMessageMutation] = useMutation(closeGroupMessage)
  const router = useRouter()
  return (
    <Dialog.Root
      open
      onOpenChange={async () => {
        await closeGroupMessageMutation({ type: "MIGRATE_MESSAGE" })
        await invalidateQuery(getMigrationDialog)
      }}
    >
      <Dialog.Portal>
        <Dialog.Overlay asChild>
          <Overlay />
        </Dialog.Overlay>
        <Dialog.Content>
          <Root>
            <Content>
              <Stack gap="8px">
                <Stack gap="24px">
                  <Title size={PX24}>
                    <Value name="apps.web.src.schooljaren.components.migrationhomedialog.veel_plezier_in_het_nieuwe_schooljaar">
                      Veel plezier in het nieuwe schooljaar!
                    </Value>
                  </Title>
                  <Hr />
                  <div>
                    {variant === "MODAL_1" ? (
                      <Value
                        name="apps.web.src.schooljaren.components.migrationhomedialog.body.modal_1"
                        format={{
                          groupName: currentGroup?.displayTitle,
                          ...htmlFormatTags,
                        }}
                      >
                        {`De groepslijsten van {groupName} zijn succesvol
                        overgezet naar het huidige schooljaar. Maak ook meteen
                        nieuwe <b>leerlingcodes</b> aan, zodat leerlingen ook zelfstandig
                        (thuis) met 123ZING aan de slag kunnen.`}
                      </Value>
                    ) : variant === "MODAL_2" ? (
                      <Value
                        name="apps.web.src.schooljaren.components.migrationhomedialog.body.modal_2"
                        format={{
                          groupName: currentGroup?.displayTitle,
                          ...htmlFormatTags,
                        }}
                      >
                        {`De groepslijsten van {groupName} zijn
                        leeg, zodat je met een schone lei aan het nieuwe
                        schooljaar kunt beginnen. Wil je oude afspeellijsten
                        toch nog terugzien? Maak meteen <b>nieuwe leerlingcodes</b>
                        aan, zodat leerlingen ook zelfstandig (thuis) met
                        123ZING aan de slag kunnen.`}
                      </Value>
                    ) : variant === "MODAL_3" ? (
                      <Value
                        name="apps.web.src.schooljaren.components.migrationhomedialog.body.modal_3"
                        format={{
                          groupName: currentGroup?.displayTitle,
                          ...htmlFormatTags,
                        }}
                      >
                        {`Controleer meteen of er al <b>nieuwe leerlingcodes</b> zijn
                        aangemaakt, zodat leerlingen ook zelfstandig (thuis) met
                        123ZING aan de slag kunnen. Daarna ben je helemaal klaar
                        voor het nieuwe schooljaar!`}
                      </Value>
                    ) : null}
                  </div>
                </Stack>
                <Group justify="flex-start">
                  <SupportTip
                    href={SUPPORT_LINKS["KIDS_CODES"]}
                    withBackground={false}
                  >
                    <Value name="apps.web.src.schooljaren.components.migrationhomedialog.wat_zijn_leerlingcodes">
                      Wat zijn leerlingcodes?
                    </Value>
                  </SupportTip>
                </Group>
                <Group gap="8px" justify="right">
                  <Dialog.Close asChild>
                    <RoundedButton
                      onClick={async () => {
                        await closeGroupMessageMutation({
                          type: "MIGRATE_MESSAGE",
                        })
                        invalidateQuery(getMigrationDialog)
                        await router.push(Routes.LeerlingcodesPage())
                      }}
                    >
                      <Value name="apps.web.src.schooljaren.components.migrationhomedialog.leerlingcodes_aanmaken">
                        Leerlingcodes aanmaken
                      </Value>
                    </RoundedButton>
                  </Dialog.Close>
                  <Dialog.Close asChild>
                    <RoundedButton variant="filled">
                      <Value name="apps.web.src.schooljaren.components.migrationhomedialog.naar_de_leeromgeving">
                        Naar de leeromgeving
                      </Value>
                    </RoundedButton>
                  </Dialog.Close>
                </Group>
              </Stack>
              <Dialog.Close asChild>
                <Close>
                  <FaTimes />
                </Close>
              </Dialog.Close>
            </Content>
          </Root>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

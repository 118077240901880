import { Value, htmlFormatTags } from "@stringtale/react"
import { useQuery } from "@blitzjs/rpc"
import styled from "@emotion/styled"
import getGroupsInSchool from "app/auth/queries/getGroupsInSchool"
import SwitchGroupMenu from "app/groups/components/SwitchGroupMenu"
import { ComponentProps, ReactNode } from "react"
import { FaCaretDown } from "@react-icons/all-files/fa/FaCaretDown"
import { DARK_BLUE_COLOR, PX14 } from "theme/consts"
import * as Container from "ui/Container"
import { useCurrentGroup } from "../hooks/useCurrentGroup"
import { useCurrentOrganization } from "../hooks/useCurrentOrganization"
import BreadcrumbItem from "./BreadcrumbItem"
import format from "date-fns/format"

const Root = styled(Container.Root)<{ backgroundColor?: string }>`
  background-color: ${(p) => p.backgroundColor || "white"};
  /* height: 40px; */
`

const Content = styled(Container.Content)`
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: space-between;
`

const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

const BreadcrumbItems = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`

const BreadcrumbGroup = styled.span`
  color: ${DARK_BLUE_COLOR};
  font-size: ${PX14};
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 2px;
`

type Props = {
  items: Array<ReactNode>
  right?: ReactNode
} & ComponentProps<typeof Root>

function Breadcrumb({ items, right, ...props }: Props) {
  const currentGroup = useCurrentGroup()
  const organization = useCurrentOrganization()
  const [groups] = useQuery(getGroupsInSchool, {})

  return (
    <Root {...props}>
      <Content>
        <Left>
          {organization && groups && (
            <SwitchGroupMenu
              currentGroup={currentGroup}
              trigger={
                currentGroup ? (
                  <BreadcrumbGroup style={{ cursor: "pointer" }}>
                    {currentGroup.displayTitle} <FaCaretDown />
                  </BreadcrumbGroup>
                ) : (
                  <BreadcrumbGroup style={{ cursor: "pointer" }}>
                    <Value name="apps.web.src.core.components.breadcrumb.geen_actieve_groep">
                      Geen actieve groep
                    </Value>
                    <FaCaretDown />
                  </BreadcrumbGroup>
                )
              }
              groups={groups}
            />
          )}
          <BreadcrumbItems>
            {organization && groups && <span style={{ opacity: 0.5 }}>| </span>}
            {items.map((item, i) => (
              <>
                {i > 0 && <span style={{ opacity: 0.5 }}>&#62;</span>}
                {item}
              </>
            ))}
            {organization?.trialAccount && organization.trialEndDate && (
              <>
                <span style={{ opacity: 0.5 }}>|</span>
                <BreadcrumbItem isActive>
                  <Value
                    name="apps.web.src.core.components.breadcrumb.looptijd_proefabonnement_tot"
                    format={{
                      ...htmlFormatTags,
                      trialEndDate: organization.trialEndDate,
                    }}
                    locales="nl-NL"
                  >
                    {`Looptijd proefabonnement tot: <b>{trialEndDate, date, dd-MM-yyyy}</b>`}
                  </Value>
                </BreadcrumbItem>
              </>
            )}
          </BreadcrumbItems>
        </Left>
        {right && <Right>{right}</Right>}
      </Content>
    </Root>
  )
}

Breadcrumb.Item = BreadcrumbItem

export default Breadcrumb


import { Routes } from "@blitzjs/next"
import { ContentSubjectType } from "@prisma/client"
import getLinkProps from "app/core/utils/getLinkProps"
import getLearningLineLinkProps from "app/learning-lines/utils/getLearningLineLinkProps"
import Link from "ui/Link"


type Props = {
  type: "EXTERN" | "THEME" | "LESSON" | "NEWS" | "TIP" | "LEARNING_LINE" | "LESSON_LINKED"
  url?: string | null
  urlTarget?: "BLANK" | "SELF" | null
  theme?: { slug: string, category: { slug: string } } | null
  lesson?: { slug: string } | null
  learningLine?: { slug: string, learningLineTheme: { slug: string, learningLine: { slug: string, contentSubject: ContentSubjectType } | null } | null } | null
  // news?: { slug: string } | null
  // tip?: { slug: string } | null
}

export const getCardLinkProps = (card: Props) => {
  if (card.type === "EXTERN" && card.url) {
    return getLinkProps(card.url, card.urlTarget || "SELF")
  }
  if (card.type === "THEME" && card.theme) {
    return {
      as: Link,
      ...Routes.ThemeIndexPage({
        theme: card.theme.slug,
        category: card.theme.category.slug,
      })
    }
  }
  if ((card.type === "LESSON" || card.type === "LESSON_LINKED") && card.lesson) {
    return {
      as: Link,
      ...Routes.LessonPage({
        lesson: card.lesson.slug,
      })
    }
  }
  // if (card.type === "NEWS" && card.news) {
  //   return {}
  // }
  // if (card.type === "TIP" && card.tip) {
  //   return {}
  // }
  if (card.type === "LEARNING_LINE" && card.learningLine) {
    const props = getLearningLineLinkProps(card.learningLine)
    if (!props) {
      return {}
    }
    return {
      as: Link,
      ...props
    }
  }
}
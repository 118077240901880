import styled from "@emotion/styled"
import Image from "next/image"
import { ReactNode } from "react"
import { LIGHTER_GREY_COLOR } from "theme/colors"
import Group from "./Group"

const Root = styled(Group)<{ withBackground: boolean }>`
  background-color: ${(p) => (p.withBackground ? LIGHTER_GREY_COLOR : "none")};
  border-radius: ${(p) => (p.withBackground ? "8px" : 0)};
  padding-left: ${(p) => (p.withBackground ? "16px" : 0)};
  padding-right: ${(p) => (p.withBackground ? "16px" : 0)};
  padding-top: 16px;
  padding-bottom: 16px;
  justify-content: center;
  align-items: center;
  text-decoration: "underline";
`

type IconName = "question" | "exclamation" | "tip"

type IconStyle = "light" | "dark"

const supportTipIcon = function ({
  iconName,
  iconStyle,
}: {
  iconName: IconName
  iconStyle: IconStyle
}) {
  if (iconStyle) {
    switch (iconName) {
      case "exclamation":
        return "/icons/support_tip/ico_support_emark.svg"
      case "question":
        return "/icons/support_tip/ico_support_qmark.svg"
      case "tip":
        return "/icons/support_tip/ico_support_tiptip.svg"
    }
  } else {
    switch (iconName) {
      case "exclamation":
        return "/icons/support_tip/ico_support_emark_wh.svg"
      case "question":
        return "/icons/support_tip/ico_support_qmark_wh.svg"
      case "tip":
        return "/icons/support_tip/ico_support_tiptip_wh.svg"
    }
  }
}

export default function SupportTip({
  href,
  withBackground = true,
  iconName = "question",
  iconStyle = "dark",
  children,
  style,
}: {
  href: string
  iconName?: IconName
  iconStyle?: IconStyle
  withBackground?: boolean
  children: ReactNode
  style?: React.CSSProperties
}) {
  return (
    <a
      href={href}
      target="_blank"
      rel={"noreferrer"}
      style={{
        display: "block",
        ...style,
      }}
    >
      <Root gap="12px" withBackground={withBackground}>
        <Image
          src={supportTipIcon({ iconName, iconStyle })}
          width={37}
          height={34}
          alt="Tip icon"
        />
        {children}
      </Root>
    </a>
  )
}

import styled from "@emotion/styled"
import { BlueprintState } from "db"
import {
  BACKGROUND_BLUE,
  LIGHT_GREEN_COLOR,
  BACKGROUND_YELLOW,
  SYSTEM_BLUE,
  GREEN_COLOR,
  SYSTEM_YELLOW,
} from "theme/colors"
import Stack from "ui/Stack"
import { GetBlueprintResult } from "../queries/getBlueprint"
import Title from "ui/Title"
import Text from "ui/Text"
import { Value, htmlFormatTags } from "@stringtale/react"
import RoundedButton from "ui/RoundedButton"
import SupportTip from "ui/SupportTip"
import { SUPPORT_LINKS } from "app/core/consts"
import { format } from "date-fns"
import { nl } from "date-fns/locale"
import Group from "ui/Group"
import { BlueprintLabel } from "../Layout"
import { PX20, PX24 } from "theme/sizes"
import React from "react"
import { useRouter } from "next/router"
import { Routes } from "@blitzjs/next"
import { BlueprintBannerState } from "./SchoolManagementSchoolyearBanner"
import { HomepageMessageBanner } from "app/home/components/HomepageMessageBanner"

export default function SchoolyearBanner({
  blueprint,
  style,
}: {
  blueprint: NonNullable<GetBlueprintResult>
  style?: React.CSSProperties
}) {
  const router = useRouter()
  const state: BlueprintBannerState =
    blueprint.blueprints.length === 0
      ? "NEW"
      : blueprint.blueprints[0].state === "CONCEPT"
        ? "CONCEPT"
        : "PUBLISHED"

  switch (state) {
    case "NEW":
      return (
        <HomepageMessageBanner
          style={style}
          variant="blue"
          buttonText={
            <Value name="apps.web.src.schooljaren.components.homepagebanner.start_met_de_schooljaarovergang">
              Start
            </Value>
          }
          buttonAction={async () => {
            await router.push(Routes.SchooljarenStep1Page())
          }}
        >
          <Group align="center" gap={"8px"}>
            <Title as={"h2"} size={PX20}>
              <Value
                format={{
                  year: blueprint.displayTitle,
                  launchDate: format(blueprint.yearStartDate, "d MMMM", {
                    locale: nl,
                  }),
                }}
                name="apps.web.src.schooljaren.components.homepagebanner.actie_vereist"
              >
                {`Actie vereist vóór {launchDate}: Schooljaarovergang {year}`}
              </Value>
            </Title>
          </Group>
        </HomepageMessageBanner>
      )
    case "CONCEPT":
      return (
        <HomepageMessageBanner
          style={style}
          variant="yellow"
          buttonText={
            <Value name="apps.web.src.schooljaren.components.homepagebanner.voltooi_de_schooljaarovergang">
              Voltooi
            </Value>
          }
          buttonAction={async () =>
            await router.push(Routes.SchooljarenStep2Page())
          }
        >
          <Group align="center" gap={"8px"}>
            <Title as={"h2"} size={PX20}>
              <Value
                format={{
                  year: blueprint.displayTitle,
                  launchDate: format(blueprint.yearStartDate, "d MMMM", {
                    locale: nl,
                  }),
                }}
                name="apps.web.src.schooljaren.components.homepagebanner.voltooi_concept"
              >
                {`Voltooi vóór {launchDate}: Schooljaarovergang {year}`}
              </Value>
            </Title>

            <BlueprintLabel blueprint={blueprint}>
              <Value name="apps.web.src.schooljaren.layout.label.concept">
                {`Concept`}
              </Value>
            </BlueprintLabel>
          </Group>
        </HomepageMessageBanner>
      )
    default:
      return null
  }
}
